
export const initFooter = () => {
	const footer = document.querySelector('.footer')
	const cta = document.querySelector('.fixed-cta')
	if (footer && cta) {
		footerSpacer.observe(footer)
	}
}

export const footerSpacer = new IntersectionObserver(entries => {
	entries.forEach(entry => {
		const cta = document.querySelector('.fixed-cta')
		if (entry.isIntersecting) {
			cta.classList.add('hide')
		} else {
			cta.classList.remove('hide')
		}
	})
})
