import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isTouch } from '../base/scroll.js'
gsap.registerPlugin(ScrollTrigger)


export const initScrollTriggers = () => {
	initShadowTrigger()
	initParallaxElements()
	initScrollGallery()
}


const initShadowTrigger = () => {
	const shadows = document.querySelectorAll('.results__screen')
	if (shadows) {
		shadows.forEach(shadow => {
			gsap.to(shadow, {
				scrollTrigger: {
					trigger: shadow,
					scroller: !isTouch() ? '.smooth-scroll' : null,
					start: 'top-=50%',
					scrub: true
				},
				'--y-angle': '-50px',
				'--x-angle': '-10px'
			})
		})
	}
}


const initParallaxElements = () => {
	const elements = document.querySelectorAll('[data-parallax]')
	if (elements) {
		elements.forEach(element => {
			const animations = {
				translateX: element.dataset.translatex ? element.dataset.translatex : null,
				translateY: element.dataset.translatey ? element.dataset.translatey : null,
				opacity: element.dataset.opacity ? element.dataset.opacity : null,
			}
			animations.scrollTrigger = {
				trigger: element.dataset.trigger ? element.dataset.trigger : element,
				scroller: '.smooth-scroll',
				start: element.dataset.start ? element.dataset.start : '-100%',
				end: element.dataset.end ? element.dataset.end : 'bottom',
				scrub: element.dataset.scrub ? element.dataset.scrub : false
			}
			gsap.to(element, 1, animations)
		})
	}
}

const initScrollGallery = () => {
	const scrollGallery = document.querySelectorAll('.scroll-gallery')
	if (scrollGallery) {
		scrollGallery.forEach(gallery => {
			gsap.to(gallery, {
				scrollTrigger: {
					trigger: gallery,
					scroller: '.smooth-scroll',
					start: 'top-=50%',
					end: 'top+=20%',
					scrub: true,
					immediateRender: false
				},
				'--percentage': 1
			})
		})
	}
}
