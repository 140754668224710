import { Swiper, Scrollbar } from 'swiper'
Swiper.use(Scrollbar)

export const initServiceSlider = () => {
	const serviceSlider = document.querySelectorAll('.marketing-cloud__slider')
	serviceSlider.forEach(slider => {
		new Swiper(slider, {
			wrapperClass: 'marketing-cloud__wrapper',
			slideClass: 'service-card',
			spaceBetween: 16,
			slidesPerView: 'auto',
			slidesOffsetBefore: 20,
			watchOverflow: true,
			scrollbar: {
				el: '.marketing-cloud__scrollbar',
				draggable: true,
			},
			breakpoints: {
				1025: {
					spaceBetween: 30,
					slidesPerView: 'auto',
					slidesOffsetBefore: 30,
					slidesOffsetAfter: 30,
				}
			}
		})
	})
}

export const initRelatedSlider = () => {
	const relatedSlider = document.querySelectorAll('.related-cases__slider')
	relatedSlider.forEach(slider => {
		new Swiper(slider, {
			wrapperClass: 'related-cases__wrapper',
			slideClass: 'case-card',
			spaceBetween: 16,
			slidesPerView: 'auto',
			slidesOffsetBefore: 24,
			watchOverflow: true,
			scrollbar: {
				el: '.related-cases__scrollbar',
				draggable: true,
			},
			breakpoints: {
				750: {
					slidesOffsetBefore: 0,
				},
				1025: {
					spaceBetween: 30,
					slidesPerView: 'auto',
					slidesOffsetBefore: 30,
					slidesOffsetAfter: 30,
				}
			}
		})
	})
}
