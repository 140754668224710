<template>
  <div data-scroll-container class="smooth-scroll">
    <main>
      <section data-scroll-section class="simple-header color--light">
        <div class="simple-header__content">
          <div class="simple-header__inner">
            <h1 data-scroll class="simple-header__title xxlarge-heading">Create your <br>email signature</h1>
            <h2>Enter your details in the form on the left and copy paste the signature in your email client.</h2>
          </div>
        </div>
        <section class="form  color--dark" data-scroll>
          <Form />
        </section>
      </section>
    </main>
  </div>
</template>

<script>
import Form from "../form/Form.vue";
export default {
  data: function () {
    return {
      mode: process.env.NODE_ENV,
    };
  },
  components: {
    Form,
  },
  methods: {
    clone () {
      window.prompt("Copy to clipboard: Ctrl+C, Enter", this.$refs.element.outerHTML);
      // this.$refs.element.cloneNode(true)
    }
  },
};
</script>
