<template>
  <div class="conditional radio">
    <h3 v-if="field.section" class="form__section"> {{ field.section }} <span class="form__legend">* Verplicht veld</span></h3>
    <label v-if="field.label"
      :class="field.labelClass ? field.labelClass : 'field__label'"
      :data-active="focus"
      @click="onLabelClick()"
    >
      {{ field.label }}
    </label>
    <span
      v-if="field.subtitle"
      :class="field.subtitleClass ? field.subtitleClass : 'field__subtitle'"
    >
      {{ field.subtitle }}
    </span>
    <FieldValidator ref="validator" :validation="field.validation">
      <div :class="field.class ? field.class : 'conditional__container'">
        <div v-for="option in field.options" :class="field.condition === option.value ? 'conditional__on' : 'conditional__off'" :key="option.value">
          <input
            :id="option.value"
            :value="option.value"
            :name="field.name"
            ref="field"
            type="radio"
            v-model="value"
            class="radio__button"
            @blur="onBlur()"
            @focus="onFocus()"
          />
          <label :for="option.value"> {{ option.label }}</label>
        </div>
        <TextInput v-if="value === field.condition" ref="conditional" :field="field.conditional"/>
      </div>
    </FieldValidator>
  </div>
</template>

<script>
import FieldValidator from "@/components/form/validation/FieldValidator.vue";
import TextInput from "@/components/form/input/TextInput.vue";

/**
 * Radio button input component
 *
 * @category   Components/Input
 * @author     Tim van Lent <tim@growingminds.nl>
 * @copyright  2021 GrowingMinds
 * @since      0.0.1
 */
export default {
  name : 'ConditionalTextInput',
  components: {
    FieldValidator,
    TextInput,
  },
  props: {
    prefill: {
      type: String,
      default: "",
    },
    field: {
      type: Object,
      default: () => {
        return {
          value : ""
        };
      }
    }
  },
  data : function() {
    return {
      value: this.field.value
    }
  },
  methods: {
    /**
     * on blur
     */
    onBlur: function () {
      // on blur validate and send value to tag manager if enabled
      return this.$refs.validator.validate(this.$refs.field, this.value, true);
    },

    /**
     * on focus
     */
    onFocus: function () {

      // in case tracking is activated 
      if (window.utag && window.utag_data) {
        const trackingObj = Object.assign({}, window.utag_data, {
          tealium_event: 'form_focus_change',
          eventLabel: 'focus change',
        });

        trackingObj[this.field.name] = this.value;

        console.info('FORM_FOCUS_CHANGE - RADIO');
        window.utag.link(trackingObj);
      }
    },

    /**
     * on label click
     */
    onLabelClick : function () {
      this.focus = true
      this.$refs.field.focus();
    },

    /**
     * Validates the field and returns its valid state
     * @returns bool
     */
    validate: function () {
      const validRadio = this.$refs.validator.validate(this.$refs.field, this.value);

      // if the conditional isnt visible, its automatically valid
      const validConditional = this.$refs.conditional ? this.$refs.conditional.validate() : true;

      // done
      return validRadio && validConditional;
    }
  },
  watch: {
    value: function () {
      this.validate();
      this.$nextTick(() => {
        // Code that will run only after the entire view has been re-rendered
        if (this.value === this.field.condition) this.$refs.conditional.$refs.field.focus();
      })
    }
  },
};

</script>