import { gsap } from 'gsap'

export const initCounter = () => {
	const numbers = document.querySelectorAll('.js-counter')
	if (numbers) {
		numbers.forEach((num) => {
			const splitted = num.innerText.split('')
			replaceHTML(num, splitted)
			watchCounterIntersection.observe(num)
		})

	}
}


const replaceHTML = (num, splitted) => {
	num.innerHTML = ''
	splitted.forEach((split) => {
		if (!isNaN(parseFloat(split))) {
			num.innerHTML += `<span class="split" data-nr="${split}">${split}</span>`
		} else {
			num.innerHTML += `<span class="char" data-nr="${split}">${split}</span>`
		}
	})
}

const watchCounterIntersection = new IntersectionObserver(entries => {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			const splits = entry.target.querySelectorAll('.split')
			const tl = gsap.timeline()
			tl.to(splits, {
				y: 'random(-100, -80)%',
				delay: 'random(0.3, 0.6)'
			})
			tl.to(splits, {
				y: 'random(-100, 100)%',
				duration: 'random(0.1, 0.2)'
			})
			tl.to(splits, {
				y: 'random(-100, 100)%',
				duration: 'random(0.1, 0.2)',
			})
			tl.to(splits, {
				y: '0',
				onComplete: () => {
					gsap.to(splits, {
						'--opacity': '0',
					})
				}
			})
			watchCounterIntersection.unobserve(entry.target)
		}
	})
})
