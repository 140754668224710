<template>
  <div :class="field.width ? `width-${field.width} text field` : 'text field'">
    <h3 v-if="field.section" class="form__section"> {{ field.section }} <span class="form__legend">* Verplicht veld</span></h3>
    <label v-if="field.label"
      :class="field.labelClass ? field.labelClass : 'field__label'"
      :data-active="focus"
      @click="onLabelClick()"
    >
      {{ field.label }}
    </label>
    <span
      v-if="field.subtitle"
      :class="field.subtitleClass ? field.subtitleClass : 'field__subtitle'"
    >
      {{ field.subtitle }}
    </span>
    <FieldValidator ref="validator" :validation="field.validation">
      <div class="field__container">
        <textarea
          :rows="field.rows || '5'"
          :name="field.name"
          v-model="value"
          ref="field"
          :placeholder="field.placeholder"
          :class="field.class ? field.class : 'text__input'"
          @focus="onFocus()"
          @blur="onBlur()"
          @keydown="onChange"
        />
      </div>
    </FieldValidator>
  </div>
</template>

<script>
import FieldValidator from "@/components/form/validation/FieldValidator.vue";
import transformers from "@/plugins/Transformers.js";

/**
 * Text input component
 *
 * @category   Components/Input
 * @author     Tim van Lent <tim@growingminds.nl>
 * @copyright  2021 GrowingMinds
 * @since      0.0.1
 */
export default {
  name : 'TextArea',
  components: {
		FieldValidator
  },
  props: {
    prefill: {
      type: String,
      default: "",
    },
    field: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data : function() {
    return {
      value: this.prefill,
      focus: false,
      debounce: null
    }
  },
  methods: {
    
    /**
     * on blur
     */
    onBlur: function () {
      this.focus = false;

      // emit change
      this.$emit('blur');

      // on blur validate and send value to tag manager if enabled
      return this.$refs.validator.validate(this.$refs.field, this.value, true);
    },

    /**
     * on focus
     */
    onFocus: function () {
      this.focus = true;

      // in case tracking is activated 
      if (window.utag && window.utag_data) {
        const trackingObj = Object.assign({}, window.utag_data, {
          tealium_event: 'form_focus_change',
          eventLabel: 'focus change',
        });
        console.info('FORM_FOCUS_CHANGE - TEXT');
        trackingObj[this.field.name] = this.value;
        window.utag.link(trackingObj);
      }
    },

    onChange : function (event) {
      let val = event.target.value;

      // transform
      if (this.field.transform) for (let modifier of this.field.transform) {
        const transformer = transformers[modifier];
        if (typeof transformer === 'function'){
          const newValue = transformers[modifier](val, event);
          if (this.value !== newValue) {
            this.value = newValue;
            return;
          }
        }
      }

      // validate
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.validate();
      }, 300)
    },

    /**
     * on label click
     */
    onLabelClick : function () {
      this.focus = true
      this.$refs.field.focus();
    },

    /**
     * Validates the field and returns its valid state
     * @returns bool
     */
    validate: function () {
      return this.$refs.validator.validate(this.$refs.field, this.value);
    },

    /**
     * Get the inputs valid state
     */
    isValid : function () {
      return this.$refs.validator.isValid();
    }
  }
};

</script>
