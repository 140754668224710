import Plyr from 'plyr'
import 'plyr/dist/plyr.css'

export const initVideoControls = () => {
	const videosWithControls = document.querySelectorAll('video[controls]')
	if (videosWithControls) {
		videosWithControls.forEach(video => {
			new Plyr(video)
		})
	}
}
