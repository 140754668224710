<template>
  <div class="checkbox-group" ref="field">
    <div v-if="field.section" class="form__section"><h3> {{ field.section }} </h3></div>
    <label
      v-if="field.label"
      :class="field.labelClass ? field.labelClass : 'field__label'"
    >
      {{ field.label }}
    </label>
    <div v-if="field.switchable" class="switch-button">
      <input class="switch-button__button" v-model="checkboxValue" :ref="field.name" type="checkbox" :id="field.name" />
      <label class="switch-button__label" :for="field.name"></label>
    </div>
    <div class="checkbox-group__container">
      <FieldValidator ref="validator" :validation="field.validation">
        <div v-for="(option) in field.options" class="form__checkbox-row" :key="option.value">
          <div class="checkbox checkbox__checkbox-work">
            <div :class="option.class ? option.class : 'checkbox__container'">
              <input
                :ref="option.value"
                :id="option.value"
                :name="option.value"
                :checked="option.checked"
                type="checkbox"
                class="checkbox__input"
                :disabled="field.switchable ? !checkboxValue : false"
              />
              <label v-if="option.label" :for="option.value" class="small"> {{ option.label }} </label>
              <span
                v-if="option.subtitle"
                :class="option.subtitleClass ? option.subtitleClass : 'field__subtitle'"
              >
                {{ option.subtitle }}
              </span>
            </div>
          </div>
        </div>
      </FieldValidator>
    </div>
  </div>
</template>

<script>
import FieldValidator from "@/components/form/validation/FieldValidator.vue";

/**
 * Holds multiple checkboxes
 *
 * @category   Components/Input
 * @author     Tim van Lent <tim@growingminds.nl>
 * @copyright  2021 GrowingMinds
 * @since      0.0.1
 */
export default {
  name : 'CheckboxGroupWorkDays',
  components: {
    // Checkbox,
    FieldValidator
  },
  props: {
    prefill: {
      type: String,
      default: "",
    },
    field: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data : function() {
    return {
      value: this.prefill,
      valid: true,
      msg: '',
      checkboxValue: 0
    }
  },
  mounted: function () {

    // iterate over the checkboxes to validate on a checkbox change
    for (let checkbox of Object.values(this.$refs).filter((x) => x.type === 'checkbox')) {
      checkbox.addEventListener('change', () => {

        if (checkbox.checked) {
          this.$emit('passValue', 1, checkbox.name)
        } else {
          this.$emit('passValue', 0, checkbox.name)
        }

        this.validate();
      });
    }
  },
  methods:{
    validate: function () {

      // compose nodeCollection
      const nodes = Object.values(this.$refs).filter((x) => x.type === 'checkbox');

      let count = 0;
      for (var i=0; i < nodes.length; i++) {
        if (nodes[i].checked != true){
            count++;
        }
      }

      if (count < nodes.length) {
        return this.$refs.validator.validate(this, true);
      } else {
        return this.$refs.validator.validate(this, false);
      }
    }
  },
  watch: {
    checkboxValue: function () {
      if (this.checkboxValue) {
        for (let checkbox of Object.values(this.$refs).filter((x) => x.type === 'checkbox')) {
          if (checkbox.checked) {
            this.$emit('passValue', 1, checkbox.name)
          }
        }
      } else {

        for (let checkbox of Object.values(this.$refs).filter((x) => x.type === 'checkbox')) {
          if (checkbox.checked) {
            this.$emit('passValue', 0, checkbox.name)
          }
        }
      }
    }
  }
};

</script>
