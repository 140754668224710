export const initFixedCTA = () => {
	const ctas = [...document.querySelectorAll('.js-fixed-contact')]
	const ctaContact = document.querySelector('.fixed-contact-block')
	if (ctas && ctaContact) {
		const closeTriggers = [...ctaContact.querySelectorAll('.js-close')]

		ctas.forEach(cta => {
			cta.addEventListener('click', () => {
				ctaContact.classList.add('active')
			})
		})
		closeTriggers.forEach(trigger => {
			trigger.addEventListener('click', () => {
				ctaContact.classList.remove('active')
			})
		})
	}
}
