<template>
  <StandByHeader />
  <Main />
  <!-- <ThankYouContent /> -->
</template>

<script>
  import StandByHeader from "../components/sections/StandByHeader.vue";
  import Main from "../components/sections/Main.vue";
  // import ThankYouContent from "../components/sections/ThankYouContent.vue";
  export default {
    data: function () {
      return {
        mode : process.env.NODE_ENV
      }
    },
    components: {
      StandByHeader,
      Main
      // ThankYouContent
    }
  }
</script>
