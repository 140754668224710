<template>
  <div class="holder">
    <div class='two_column_layout' id="scroll-direction">
      <form
      ref="form"
      @submit.prevent="validate"
      class="form-container"
      >
      <input type="hidden" name="contactKey"/>
      <div class="form__container">
        <div class="form">
          <div class="form__body">
            <div class="form__content full-width flex">
              <h2 class="xlarge-body">Personal information</h2>
              <p class="smallst">
                * Required field
              </p>
            </div>
            <div class="form__row" v-for="row in personalFieldsRows" :key="row">
              <component :is="field.component" v-for="field in row" :ref="field.name" :key="field.name" @passValue="callback" :field="field"/>
            </div>
          </div>
        </div>
        <div class="form__actions">
          <button type="submit" class="button button--basic background--accent">
            <span class="button__inner">
              <span class="button__icon">
                <svg viewBox="0 0 16 14">
                  <g fill-rule="nonzero">
                    <path
                    d="M13.566 6.125H.71a.625.625 0 00-.625.625v.5c0 .345.28.625.625.625h12.856c.345 0 .625-.28.625-.625v-.5a.625.625 0 00-.625-.625z"
                    ></path>
                    <path
                    d="M15.025 6.548l-4.87-5.288-.037-.036a.625.625 0 00-.883.036l-.312.338a.625.625 0 000 .847l4.871 5.288.036.036c.254.234.65.218.884-.036l.311-.338a.625.625 0 000-.847z"
                    ></path>
                    <path
                    d="M13.794 6.262L8.923 11.55a.625.625 0 000 .847l.33.357.018.017c.253.234.649.218.883-.036l4.87-5.288a.625.625 0 000-.847l-.329-.356-.018-.018a.625.625 0 00-.883.036z"
                    ></path>
                  </g>
                </svg>
              </span>
              <span class="button__text">Copy signature</span>
            </span>
          </button>
          <span v-if="copied" class="copied">&nbsp;Copied to clipboard!</span>
        </div>
      </div>
    </form>
    <div class="contact__side background--light" data-scroll data-scroll-sticky data-scroll-target="#scroll-direction">
      <!-- actual signature -->
      <p class="spec">Hi Julie, <br><br>How do you like my ✨ new e-mail signature?</p>
      <table ref="element">

        <p style="-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; letter-spacing: -.24px; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; color: #14151A; font-weight: 700; margin-top: 24px;">{{ fieldnames.firstname }}<span v-if="fieldnames.suffix">&nbsp;{{ fieldnames.suffix }}</span> {{ fieldnames.lastname }}<br /><span style="color: #888888; font-weight: 400; font-size: 12px; letter-spacing: 0px;">{{ fieldnames.jobtitle }}</span><br><br></p>
        <table id="talpaHolder" cellspacing='0' cellpadding="0" border-spacing="0" style="margin: 0 0 0 -7px; padding: 0; heigh: 34px;">
          <tr>
            <td width="67" height="24" valign="top" style="width: 67px; height: 24px; margin: 0; padding: 5px 12px 5px 7px; background-color: #FFF; border-radius: 17px;">
              <a href="https://www.talpa.com/" title="Talpa" style="border: none; text-decoration: none !important; text-decoration: none; background-color: transparent; display: block; width: 67px; height: 24px;">
                <img moz-do-not-send="true" src="https://talpa.com/email/logostudios.png" width="80" alt="Talpa Studios - Beyond compare, beyond borders" style="display: block; border: 0px; outline: none; width: 100%; height: auto; max-width: 67px;" />
              </a>
            </td>
          </tr>
        </table>

        <p style="-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; letter-spacing: -.24px; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 12px; line-height: 18px; color: #14151A; font-weight: 400; margin-bottom: 0px;">
          <br><a style="text-decoration: none !important; text-decoration: none; color: #14151A;" :href="'mailto:'+fieldnames.email"><span style="text-decoration: none !important; text-decoration: none; white-space: nowrap; font-size: 12px; color: #14151A;">{{ fieldnames.email }} </span></a>
          <span style="font-size: 10px;">&nbsp;●&nbsp;</span>
          <span v-if="fieldnames.phone" style="text-decoration: none !important; text-decoration: none; white-space: nowrap; font-size: 12px;">{{ fieldnames.phone }}<span style="font-size: 10px;">&nbsp;●&nbsp;</span></span>
          <a href="https://talpa.com/" style="text-decoration: none !important; text-decoration: none; white-space: nowrap; font-size: 12px;"><span style="color: #14151A">talpastudios.com</span></a><br>
          <span v-if="fieldnames.workdays" style="color: #AAA; font-size: 12px;">I’m not available on: {{ fieldnames.workdays }}</span>
        </p>
        <br>
      </table>
    </div>
  </div>
</div>
</template>
<script>


// form config
import personalFields from "/config/personalfields.json";

// components
import TextInput from "@/components/form/input/TextInput.vue";
import TextArea from "@/components/form/input/TextArea.vue";
import Checkbox from "@/components/form/input/Checkbox.vue";
import RadioInput from "@/components/form/input/RadioInput.vue";
import SelectInput from "@/components/form/input/SelectInput.vue";
import CheckboxGroupWorkDays from "@/components/form/input/CheckboxGroupWorkDays.vue";
import ConditionalTextInput from "@/components/form/input/ConditionalTextInput.vue";

export default {
  name: "Form",
  components: {
    TextInput,
    TextArea,
    RadioInput,
    Checkbox,
    CheckboxGroupWorkDays,
    SelectInput,
    ConditionalTextInput
  },
  data: function() {
    return {
      fields: { personalFields },
      valid: true,
      msg: '',
      copied: 0,
      fieldnames: {
        firstname: '',
        suffix: '',
        lastname: '',
        jobtitle: '',
        phone: '',
        email: '',
        workdays: ''
      }
    }
  },
  mounted: function () {

  },
  computed: {
    personalFieldsRows: function () {
      for (let [key, field] of Object.entries(this.fields.personalFields)) {
        if (field.exclude) delete this.fields.personalFields[key];
      }
      return this.fieldRows(this.fields.personalFields);
    }
  },
  methods: {
    indentHTML: function (html) {
      var tab = '\t';
      var result = '';
      var indent= '';

      html.split(/>\s*</).forEach(function(element) {
        if (element.match( /^\/\w/ )) {

          indent = indent.substring(tab.length);
        }

        result += indent + '<' + element + '>\r\n';

        if (element.match( /^<?\w[^>]*[^/]$/ ) && !element.startsWith("input")  ) {
          indent += tab;
        }
      });

      return result.substring(1, result.length-3);
    },
    allReplace : function(obj) {
      var retStr = this;
      for (var x in obj) {
        retStr = retStr.replace(new RegExp(x, 'g'), obj[x]);
      }
      return retStr;
    },
    callback: function (event, fieldName) {
      for (var field in this.fieldnames) {
        if (fieldName.field) {
          if (field === fieldName.field.name) {
            this.fieldnames[field] = event ? event : ''
          }
        }

        if (field === fieldName) {
          this.fieldnames[field] = event ? event : ''
        }
      }
    },
    fieldRows: function (data = {}) {
      const rows = {};
      for (let field in data) {
        if (!data[field].group) rows[field] = [data[field]];
        else {
          if (!Array.isArray(rows[data[field].group])) rows[data[field].group] = [];
          rows[data[field].group].push(data[field]);
        }
      }
      return rows;
    },
    SelectText() {
      var doc = document
      , text = this.$refs.element
      , range, selection
      ;
      if (doc.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(text);
        range.select();
      } else if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(text);
        selection.removeAllRanges();
        selection.addRange(range);
        doc.execCommand("Copy");
      }
    },
    validate: function () {

      // innocent until proven guilty
      let valid = true;

      // iterate over the form parts
      for (let fieldpart in this.fields) {

        // iterate over the fields
        for (let field in this.fields[fieldpart]) {

          let data = this.fields[fieldpart][field]

          // the field field
          const fieldComp = this.$refs[data.name] || this.$refs.addressFields.$refs[data.name];

          // if this field doesnt exist continue
          if (!fieldComp) continue;

          // validate field
          const fieldIsValid = fieldComp.validate();

          // if guilty
          if (!fieldIsValid) {

            // see if this is first error
            if (valid) {

              // smoothly scroll to the field
              fieldComp.$refs.field.scrollIntoView({
                behavior: "smooth",
                block: "center"
              });

              // put field in focus
              fieldComp.$refs.field.focus({preventScroll: true});

              // charge guilty
              valid = false;
            }
          }
        }
      }
      // submit form when all is valid
      if (valid) {

        String.prototype.allReplace = function(obj) {
          var retStr = this;
          for (var x in obj) {
            retStr = retStr.replace(new RegExp(x, 'g'), obj[x]);
          }
          return retStr;
        }

        // Select and Copy signature
        this.SelectText()


        // create HTML maybe in future
        // navigator.clipboard.writeText(this.indentHTML(this.$refs.element.outerHTML.allReplace({'<!--v-if-->': ''})))


        this.copied = 1

        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.copied = 0
        }, 3000)
      }
    }
  }
};

</script>
