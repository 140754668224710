<template>
  <div :class="field.imspecial ? 'radio radio--imspecial' : 'radio' ">
    <div v-if="field.section" class="form__section" v-html="field.section"></div>
    <div v-if="field.imspecial" class="field--imspecial"><span class="plate-number">KKB - 2</span></div>
    <label 
      v-if="field.label" 
      :class="field.labelClass ? field.labelClass : 'field__label'"
    > 
      {{ field.label }} 
    </label>
    <span
      v-if="field.subtitle"
      :class="field.subtitleClass ? field.subtitleClass : 'field__subtitle'"
    >
      {{ field.subtitle }}
    </span>
    <FieldValidator ref="validator" :validation="field.validation">
      <div :class="field.class ? field.class : 'radio__container'">
        <div v-for="option in field.options" :key="option.value">
          <input
            :id="option.value"
            :value="option.value"
            :name="field.name"
            ref="field"
            type="radio"
            v-model="value"
            class="radio__button"
            @blur="onBlur()"
            @focus="onFocus()"
          />
          <label :for="option.value"> {{ option.label }}</label>
        </div>
      </div>
    </FieldValidator>
  </div>
</template>

<script>
import FieldValidator from "@/components/form/validation/FieldValidator.vue";

/**
 * Radio button input component
 *
 * @category   Components/Input
 * @author     Tim van Lent <tim@growingminds.nl>
 * @copyright  2021 GrowingMinds
 * @since      0.0.1
 */
export default {
  name : 'RadioInput',
  components: {
		FieldValidator
  },
  props: {
    field: {
      type: Object,
      default: () => {
        return {
          value: ''
        };
      }
    }
  },
  data : function() {
    return {
      value: this.field.value
    }
  },
  methods: {
    /**
     * on blur
     */
    onBlur: function () {
      // on blur validate and send value to tag manager if enabled
      return this.$refs.validator.validate(this.$refs.field, this.value, true);
    },

    /**
     * on focus
     */
    onFocus: function () {

      // in case tracking is activated 
      if (window.utag && window.utag_data) {
        const trackingObj = Object.assign({}, window.utag_data, {
          tealium_event: 'form_focus_change',
          eventLabel: 'focus change',
        });

        trackingObj[this.field.name] = this.value;

        console.info('FORM_FOCUS_CHANGE - RADIO');
        window.utag.link(trackingObj);
      }
    },

    /**
     * @returns func
     */
    validate: function () {
      return this.$refs.validator.validate(this.$refs.field, this.value);
    }
  },
  watch: {
    value: function () {
      this.validate();
    }
  }
};

</script>