/**
 *  Utility object contains typical checks used for
 *  validating form input fields
 *
 *  @author Tim van Lent <tim@growingminds.nl>
 *  @copyright Grwoing Minds BV 2020
 */

/**
 *  Constructor
 */
const FormFieldChecks = function () {

  /**
   *  Regexes
   *  @var object
   */
  const regexs = {
    'REG_EMAIL': /^([\w-.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,24}|[0-9]{1,3})(]?)$/,
    'REG_TELEFOON': /^((\(\+|00(\s|\s?-\s?)?)3[0-9]\)(\s|\s?-\s?)?(\(0\)[-\s]?)?|)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/,
    'REG_TELEFOON_NL': /^(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$/,
    'REG_MOBILE_NL': /^(((\+31|0|0031)6[-\s]?)[1-9][0-9]\s?[0-9]{2}\s?[0-9]{4})$/i,
    'REG_NUMERIC': /^[0-9]+$/,
    'REG_HOUSE_NUMBER': /^[1-9][0-9]*$/,
    'REG_ALPHANUMERIC': /^[a-zA-Z0-9_\- ]+$/,
    'REG_POSTCODE_NL': /^[0-9]{4}[A-Z]{2}$/i,
    'REG_POSTCODE_NL_OR_INT': /^[0-9]{4}([0-9]?|[A-Z]{2})$/i,
    'REG_POSTCODE_WILDCARD': /^.*$/i,
    'REG_POSTCODE_NL_OR_INT_NO_SPECIAL_CHARS': /^[a-zA-Z0-9 ]{1,10}$/i,
    'REG_INITIAL': /^([a-zA-ZÀ-ǿ]\.)+$/,
    'REG_BIRTHDATE_NL': /(^(((0?[1-9]|1[0-9]|2[0-8])[-](0?[1-9]|1[012]))|((29|30|31)[-](0[13578]|1[02]))|((29|30)[-](0[4,6,9]|11)))[-](19|[2-9][0-9])\d\d$)|(^29[-]02[-](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/, ///^\d{2}\/\d{2}\/\d{4}$/,
    'REG_NAME': /^([ À-ǿa-zA-Z'-])*$/,
    'REG_NAME_DIACRITICS': /^([ À-ǿa-zA-Z'-])*$/,
    'REG_NAME_DIACRITICS_EXTENDED': /^[a-zA-ZÀ-ǿ'-]([ a-zA-ZÀ-ǿ'-()])*$/,
    'REG_CITY_DIACRITICS': /^([1-2À-ǿa-zA-Z-'() .])*$/,
    'REG_IBAN': /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}?$/,
    'REG_BIC': /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/i,
    'REG_MONEY': /^(\d+(?:[.,]\d{2})?)$/,
    'ibanCheck': /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/,
    'amount_other': /^(\d+(?:[.,]\d{2})?)$/,
  };

  /**
   *  Method to check amount is
   *  less than a given value
   *  @param  integer
   *  @param  string
   *  @return boolean
   */
  this.lessThan = (min, value) => {
    return parseFloat(value) < min;
  };

  /**
   *  Method to check amount is
   *  more than a given value
   *  @param  integer
   *  @param  string
   *  @return boolean
   */
  this.moreThan = (max, value) => {
    return parseFloat(value) > max;
  };

  /**
   *  Method to check amount is not 0
   *  @param  string
   *  @return boolean
   */
  this.isZero = value => {
    return parseFloat(value) === 0;
  };

  /**
   *  Method to check max amount
   *  @param  string
   *  @return boolean
   */
  this.maxAmount = value => {
    return parseFloat(value) > 10000;
  };

  /**
   *  Method to check if date is in the future
   *  @param  string dd-mm-yyyy
   *  @return boolean
   */
  this.futureDate = value => {

    // format date to be readable by js Date object
    let dayMonthYear = value.split('-');
    const month = dayMonthYear.splice(1, 1);
    dayMonthYear.unshift(month[0]);

    return new Date(dayMonthYear) > new Date();
  };

  /**
   *  Method to check if field is empty
   *  @param  string
   *  @return boolean
   */
  this.isEmpty = value => {
    return !value;
  };

  /**
   *  Method to check if field is empty
   *  @param  string
   *  @return boolean
   */
  this.noValidation = value => {
    return value;
  };

  /**
   *  Method to check if as checkbox is checked
   *  @return boolean
   */
  this.isNotChecked = function () {
    return !this.checked;
  };

  /**
   * Method to check if any of the checkboxes
   * in scope is checked
   * @param array
   * @return boolean
   */
  this.nonChecked = function (nodeCollection = []) {
    for (let node of nodeCollection) {
      if (node.checked) return false
    }
    return true
  };

  /**
   *  Method to check if value is too short
   *  @param  integer  minimum number of characters
   *  @param  string
   *  @return boolean
   */
  this.tooShort = (min, value) => {
    if (value == '') return false;
    return parseFloat(value.length) < parseFloat(min);
  };

  /**
   *  Method to check if value is too long
   *  @param  integer  maximum number of characters
   *  @param  string
   *  @return boolean
   */
  this.tooLong = (max, value) => {
    if (value == '' || typeof value == 'undefined') return false;
    return parseFloat(value.length) > parseFloat(max);
  };

  /**
   *  Method to check if value is between ages
   *  @param  min  maximum number of characters
   *  @param  max
   *  @param  string
   *  @return boolean
   */
  this.isntOfAge = (value) => {

    let tenYearsAgo = new Date()
    tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10);
    let fourTeenYearsAgo = new Date()
    fourTeenYearsAgo.setFullYear(fourTeenYearsAgo.getFullYear() - 14);

    var c = value.split("-");
    var checkdate = new Date(c[2], parseInt(c[1]) - 1, c[0]);

    return !(checkdate > fourTeenYearsAgo && checkdate < tenYearsAgo);
  };

  /**
   *  Method to check if value matches a regex
   *  @param  string  regular expression
   *  @param  string
   *  @return boolean
   */
  this.noMatch = (regex, value) => {
    if (value == '' || typeof value == 'undefined') return false;

    // if this is key for a regex use it,
    // otherise assume its an actual regex
    regex = Object.prototype.hasOwnProperty.call(regexs, regex) ? regexs[regex] : regex;
    return !regex.test(value);
  };

  /**
   *  Method to do a check only if
   *  a condition resolves true
   *  @param  func
   *  @return boolean
   */
  this.conditional = (condition, check) => {
    return (value) => {

      // only execute the check
      // when the condition is true
      if (condition()) {
        return check(value);

        // the field passes, no check
      } else return false;
    }
  };
}

// export instance
export default new FormFieldChecks();
