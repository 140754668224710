<template>
  <div class="d-contents">
    <slot></slot>
    <transition name="slide-fade">
      <span 
        v-if="!valid" 
        :class="errorclass"
      > {{ msg }} </span>
    </transition>
  </div>
</template>

<script>
import checks from "@/plugins/FormFieldChecks.js";

/**
 * Validator component for a single field
 *
 * @category   Components/Input
 * @author     Tim van Lent <tim@growingminds.nl>
 * @copyright  2021 GrowingMinds
 * @since      0.0.1
 */
export default {
  name : 'FieldValidator',
  props: {
    errorclass : {
      type: String,
      default: "field__error field__error--inline",
    },
    validation: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data : function() {
    return {
      valid: true,
      msg: ''
    }
  },
  methods: {

    /**
     * Validate the field
     * @returns bool
     */
    validate: function ($field, value, send = false) {

      // iteratie over validation object
      for (let rule of this.validation) {
        let func = checks[rule.assertion];

        // if no rule exist return
        if (!func) return;

        // bind field as this pointer and param to the function if given
        if (rule.param) func = func.bind($field, rule.param);
        else func = func.bind($field);

        // execute
        const result = func(value);

        // there has been an error
        if (result) {
          this.valid = false;
          this.msg = rule.message;

          // in case error are being tracked
          // with Tealium send the error information 
          if (window.utag && send && window.utag_data) {
            const trackingObj = Object.assign({}, window.utag_data, {
              tealium_event: 'form_error',
              eventLabel: 'error',
              formFieldError: $field.name,
              formErrorMessage: rule.message
            });
            console.info('FORM_ERROR');

            window.utag.link(trackingObj);
          }
          break;
        } else {
          this.valid = true;
        }
      }

      return this.valid;
    },

    /**
     * Get the input valid state
     */
    isValid : function () {
      return this.valid;
    }
  }
};

</script>
