<template>
  <div class="field">
    <div v-if="field.section" class="form__section"> <h3> {{ field.section }} </h3> </div>
    <label 
      v-if="field.label" 
      :class="field.labelClass ? field.labelClass : 'field__label'"
    > 
      {{ field.label }} 
    </label>
    <FieldValidator ref="validator" :validation="field.validation">
      <div class="field__container">
          <select 
            :name="field.name"
            ref="field"
            v-model="value" 
            class="text__input" 
            @focus="onFocus()"
            @blur="onBlur()"
          >
            <option disabled selected value="" > {{ field.placeholder }} </option>
            <option 
              v-for="option in field.options"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
      </div>
    </FieldValidator>
  </div>
</template>

<script>
import FieldValidator from "@/components/form/validation/FieldValidator.vue";

/**
 * Select box component
 *
 * @category   Components/Input
 * @author     Tim van Lent <tim@growingminds.nl>
 * @copyright  2021 GrowingMinds
 * @since      0.0.1
 */
export default {
  name : 'SelectInput',
  components: {
		FieldValidator
  },
  props: {
    prefill: {
      type: String,
      default: "",
    },
    field: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data : function() {
    return {
      value: this.prefill,
    }
  },
  methods: {
    
    /**
     * on blur
     */
    onBlur: function () {
      // on blur validate and send value to tag manager if enabled
      return this.$refs.validator.validate(this.$refs.field, this.value, true);
    },

    /**
     * on focus
     */
    onFocus: function () {

      // in case tracking is activated 
      if (window.utag && window.utag_data) {
        const trackingObj = Object.assign({}, window.utag_data, {
          tealium_event: 'form_focus_change',
          eventLabel: 'focus change',
        });

        trackingObj[this.field.name] = this.value;

        console.info('FORM_FOCUS_CHANGE - SELECT');
        window.utag.link(trackingObj);
      }
    },

    /**
     * Validates the field and returns its valid state
     * @returns bool
     */
    validate: function () {
      return this.$refs.validator.validate(this.$refs.field, this.value);
    }
  },
  watch: {
    value: function () {
      this.validate();
    }
  }
};

</script>