<template>
  <header ref="header" class="header heading-font">
    <div class="header__inner">
      <div class="header__logo">
        <a class="logo" href="https://talpa.com/">
          <svg width="89px" height="32px" viewBox="0 0 89 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Group" fill-rule="nonzero">
              <path d="M60.8557398,20.5724203 C58.0922537,20.5724203 55.8509008,22.8771422 55.8509008,25.721021 C55.8509008,28.5648998 58.0910959,30.8696218 60.8557398,30.8696218 C63.6203837,30.8696218 65.8605789,28.5648998 65.8605789,25.721021 C65.8605789,22.8771422 63.6203837,20.5724203 60.8557398,20.5724203 Z M61.0027707,29.8329597 C58.7301593,29.8329597 56.8870634,27.9921903 56.8870634,25.7198641 C56.8870634,23.4475378 58.7290016,21.6067684 61.0027707,21.6067684 C63.2765398,21.6067684 65.118478,23.4475378 65.118478,25.7198641 C65.118478,27.9921903 63.2765398,29.8329597 61.0027707,29.8329597 Z" id="Shape"></path>
              <rect id="Rectangle" x="65.1242667" y="16.4639526" width="1.02921626" height="14.4056692"></rect>
              <rect id="Rectangle" x="41.8216065" y="18.5152939" width="1.02921626" height="12.3543279"></rect>
              <polygon id="Rectangle" transform="translate(43.625919, 21.606190) rotate(90.000000) translate(-43.625919, -21.606190) " points="43.1116371 19.8030354 44.1402003 19.8030354 44.1402003 23.4093444 43.1116371 23.4093444"></polygon>
              <path d="M33.5045203,28.7384482 L34.3797593,28.1923494 C34.9447285,29.3215706 35.9114276,29.8861812 36.9499057,29.8861812 C38.3171772,29.8861812 39.1738927,29.0114976 39.1738927,28.0465688 C39.1738927,27.2447755 38.6274472,26.8074337 37.9339707,26.461494 C37.2590179,26.1155543 36.257587,25.7869694 35.6011577,25.4410297 C34.5800455,24.9122858 34.0521236,24.1116494 34.0521236,23.1640755 C34.0521236,21.7432931 35.1635382,20.5770482 37.0413659,20.5770482 C38.2442407,20.5770482 39.3556553,21.0872804 39.9761951,22.1077446 L39.2109398,22.7267337 C38.7374309,21.9249403 37.953652,21.543134 37.024,21.543134 C35.7667122,21.543134 35.1102829,22.2535252 35.1102829,23.1282088 C35.1102829,23.8027334 35.5108553,24.2759419 36.1672846,24.6033697 C36.8237138,24.9134428 37.7707317,25.2408706 38.5556683,25.6411888 C39.5582569,26.1329091 40.2690992,26.77041 40.2690992,27.9725215 C40.2690992,29.5575964 38.9203512,30.8684648 36.9522211,30.8684648 C35.6034732,30.8684648 34.2362016,30.2309639 33.5068358,28.7372912 L33.5045203,28.7384482 Z" id="Path"></path>
              <path d="M82.2354211,28.7384482 L83.1106602,28.1923494 C83.6756293,29.3215706 84.6423285,29.8861812 85.6808065,29.8861812 C87.048078,29.8861812 87.9047935,29.0114976 87.9047935,28.0465688 C87.9047935,27.2447755 87.358348,26.8074337 86.6648715,26.461494 C85.9899187,26.1155543 84.9884878,25.7869694 84.3320585,25.4410297 C83.3109463,24.9122858 82.7830244,24.1116494 82.7830244,23.1640755 C82.7830244,21.7432931 83.894439,20.5770482 85.7722667,20.5770482 C86.9751415,20.5770482 88.0865561,21.0872804 88.7070959,22.1077446 L87.9418407,22.7267337 C87.4683317,21.9249403 86.6845528,21.543134 85.7549008,21.543134 C84.497613,21.543134 83.8411837,22.2535252 83.8411837,23.1282088 C83.8411837,23.8027334 84.2417561,24.2759419 84.8981854,24.6033697 C85.5546146,24.9134428 86.5016325,25.2408706 87.2865691,25.6411888 C88.2891577,26.1329091 89,26.77041 89,27.9725215 C89,29.5575964 87.651252,30.8684648 85.683122,30.8684648 C84.334374,30.8684648 82.9671024,30.2309639 82.2377366,28.7372912 L82.2354211,28.7384482 Z" id="Path"></path>
              <path d="M75.9605593,20.5712633 C73.1148748,20.5712633 70.8086894,22.8759852 70.8086894,25.7198641 C70.8086894,28.5637429 73.1148748,30.8684648 75.9605593,30.8684648 C78.8062439,30.8684648 81.1124293,28.5637429 81.1124293,25.7198641 C81.1124293,22.8759852 78.8062439,20.5712633 75.9605593,20.5712633 Z M75.9605593,29.8352737 C73.6856325,29.8352737 71.8425366,27.9921903 71.8425366,25.7198641 C71.8425366,23.4475378 73.6867902,21.6044544 75.9605593,21.6044544 C78.2343285,21.6044544 80.0785821,23.4475378 80.0785821,25.7198641 C80.0785821,27.9921903 78.2343285,29.8352737 75.9605593,29.8352737 Z" id="Shape"></path>
              <rect id="Rectangle" x="68.2234927" y="20.5712633" width="1.02921626" height="10.2983585"></rect>
              <path d="M67.7742959,18.5002531 C67.7742959,17.9576253 68.2049691,17.5272254 68.7479415,17.5272254 C69.2909138,17.5272254 69.7019057,17.9576253 69.7019057,18.5002531 C69.7019057,19.0428809 69.2712325,19.453612 68.7479415,19.453612 C68.2246504,19.453612 67.7742959,19.0417239 67.7742959,18.5002531 Z" id="Path"></path>
              <path d="M46.9838959,27.0504013 L46.9838959,20.5735773 L48.0165854,20.5735773 L48.0165854,27.0330465 C48.0165854,28.8055535 49.1650472,29.90585 50.8518504,29.90585 C52.5386537,29.90585 53.7044813,28.8043966 53.7044813,27.0156917 L53.7044813,20.5735773 L54.7371707,20.5735773 L54.7371707,27.0504013 C54.7371707,29.389833 53.1927675,30.8696218 50.8518504,30.8696218 C48.5109333,30.8696218 46.9827382,29.3551233 46.9827382,27.0504013 L46.9838959,27.0504013 Z" id="Path"></path>
              <polygon id="Path" points="38.4306341 4.10615373 36.3756748 4.10615373 36.3756748 2.05249837 34.3218732 2.05249837 34.3218732 14.3698026 36.3756748 14.3698026 36.3756748 6.15865211 38.4306341 6.15865211"></polygon>
              <path d="M60.256039,4.10615373 C59.0033821,4.1096247 57.8028228,4.60944392 56.918322,5.49569745 L56.918322,4.10615373 L54.8645203,4.10615373 L54.8645203,18.4759563 L56.918322,18.4759563 L56.918322,12.9802589 C57.8028228,13.8665124 59.0033821,14.3663316 60.256039,14.3698026 C62.9500618,14.3698026 65.1346862,12.0720226 65.1346862,9.23739967 C65.1346862,6.40277677 62.9512195,4.10499675 60.256039,4.10499675 L60.256039,4.10615373 Z M59.9990244,12.3173042 C58.2971707,12.3173042 56.918322,10.9381734 56.918322,9.23855666 C56.918322,7.53893991 58.2983285,6.1598091 59.9990244,6.1598091 C61.6997203,6.1598091 63.0797268,7.53893991 63.0797268,9.23855666 C63.0797268,10.9381734 61.6997203,12.3173042 59.9990244,12.3173042 Z" id="Shape"></path>
              <path d="M46.6469984,5.49569745 C45.7624976,4.60944392 44.5619382,4.1096247 43.3092813,4.10615373 C40.6152585,4.10615373 38.4306341,6.40393376 38.4306341,9.23855666 C38.4306341,12.0731796 40.6152585,14.3709596 43.3092813,14.3709596 C44.5619382,14.3674886 45.7624976,13.8676694 46.6469984,12.9814159 L46.6469984,14.3709596 L48.7008,14.3709596 L48.7008,4.10615373 L46.6469984,4.10615373 L46.6469984,5.49569745 Z M43.5662959,12.3173042 C41.8644423,12.3173042 40.4855935,10.9381734 40.4855935,9.23855666 C40.4855935,7.53893991 41.8656,6.1598091 43.5662959,6.1598091 C45.2669919,6.1598091 46.6469984,7.53893991 46.6469984,9.23855666 C46.6469984,10.9381734 45.2669919,12.3173042 43.5662959,12.3173042 Z" id="Shape"></path>
              <path d="M74.3779512,4.10615373 L74.3779512,5.49569745 C73.4934504,4.60944392 72.2928911,4.1096247 71.0402341,4.10615373 C68.3462114,4.10615373 66.161587,6.40393376 66.161587,9.23855666 C66.161587,12.0731796 68.3462114,14.3709596 71.0402341,14.3709596 C72.2928911,14.3674886 73.4934504,13.8676694 74.3779512,12.9814159 L74.3779512,14.3709596 L76.4317528,14.3709596 L76.4317528,4.10615373 L74.3779512,4.10615373 Z M71.2972488,12.3173042 C69.5953951,12.3173042 68.2165463,10.9381734 68.2165463,9.23855666 C68.2165463,7.53893991 69.5965528,6.1598091 71.2972488,6.1598091 C72.9979447,6.1598091 74.3779512,7.53893991 74.3779512,9.23855666 C74.3779512,10.9381734 72.9979447,12.3173042 71.2972488,12.3173042 Z" id="Shape"></path>
              <rect id="Rectangle" x="50.7546016" y="0" width="2.05380163" height="14.3698026"></rect>
              <ellipse id="Oval" cx="14.3812423" cy="17.4520211" rx="2.9116748" ry="2.90982717"></ellipse>
              <ellipse id="Oval" cx="14.3812423" cy="29.0901728" rx="2.9116748" ry="2.90982717"></ellipse>
              <path d="M21.841613,23.4591077 C20.233535,23.4729915 18.9415154,24.786174 18.9554081,26.3932316 C18.9693008,28.0002892 20.2833171,29.2914889 21.8913951,29.277605 C23.4994732,29.2637212 24.7914927,27.9505387 24.7776,26.3434811 C24.7718114,25.6677996 24.5310049,25.0164148 24.0968585,24.4992407 C23.537678,23.8328151 22.7110634,23.4521657 21.8404553,23.4591077 L21.841613,23.4591077 Z" id="Path"></path>
              <path d="M26.3555772,16.6097332 L26.3555772,16.6097332 C24.7718114,16.3320558 23.2621398,17.3895437 22.9842862,18.9723046 C22.7064325,20.5550654 23.7645919,22.063779 25.3483577,22.3414564 C26.9321236,22.6191337 28.4406374,21.5616458 28.7196488,19.9800419 C28.743961,19.8377323 28.7590114,19.6931087 28.7624846,19.5484851 C28.7902699,18.1126618 27.7714732,16.8688987 26.3578927,16.6143611 L26.3555772,16.6097332 L26.3555772,16.6097332 Z" id="Path"></path>
              <ellipse id="Oval" cx="24.4684878" cy="11.6369947" rx="2.9116748" ry="2.90982717"></ellipse>
              <ellipse id="Oval" cx="4.28705041" cy="11.6798033" rx="2.92093659" ry="2.91908309"></ellipse>
              <ellipse id="Oval" cx="2.9116748" cy="19.4721238" rx="2.9116748" ry="2.90982717"></ellipse>
              <ellipse id="Oval" transform="translate(6.892370, 26.369446) rotate(-13.700000) translate(-6.892370, -26.369446) " cx="6.8923703" cy="26.3694459" rx="2.91157116" ry="2.90993081"></ellipse>
              <ellipse id="Oval" cx="18.3649691" cy="6.51847567" rx="2.9116748" ry="2.90982717"></ellipse>
              <ellipse id="Oval" cx="10.3975154" cy="6.51731868" rx="2.9116748" ry="2.90982717"></ellipse>
            </g>
          </svg>
        </a>
      </div>
      <div class="header__tagline">
        <a href="https://talpa.com" target="_blank" class="button button--basic"><span class="button__inner"><span class="button__text">talpastudios.com</span></span></a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "StandByHeader",
  data: function () {
    return {
      showheader: false,
    };
  },
  mounted: function () {
    const header = document.querySelector(".header");
    const app = document.querySelector("#app");
    var lastScrollTop = 0;
    app.addEventListener("scroll", () => {
        console.log('they see me scrollin')
        const st = Math.abs(header.getBoundingClientRect().top);
        const outOfView = st > header.offsetHeight;
        const condition = st < lastScrollTop && outOfView;
        if (condition && this.showheader === false) {
          // Add the component back in
          this.$refs.header.classList = "header heading-font color--light";
        } else if (!condition && this.showheader === true) {
          // upscroll code
          this.$refs.header.classList =
            "header heading-font color--light scrolled";
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      }, false);
  },
};
</script>

<style lang="scss">
</style>
