<template>
  <div class="checkbox">
    <div v-if="field.section" class="form__section"><h3> {{ field.section }} </h3></div>
    <FieldValidator ref="validator" :validation="field.validation" errorclass="field__error field__error">
      <div :class="field.class ? field.class : 'checkbox__container'">
        <input
          :id="field.name"
          :name="field.name"
          type="checkbox"
          v-model="value"
          ref="field"
          class="checkbox__input"
          @blur="onBlur()"
          @focus="onFocus()"
        />
        <label v-if="field.label" :for="field.name" v-html="field.label"></label>
        <span
          v-if="field.subtitle"
          :class="field.subtitleClass ? field.subtitleClass : 'field__subtitle'"
        > {{ field.subtitle }} </span>
      </div>
    </FieldValidator>
  </div>
</template>

<script>
import FieldValidator from "@/components/form/validation/FieldValidator.vue";

/**
 * Checkbox button input component
 *
 * @category   Components/Input
 * @author     Tim van Lent <tim@growingminds.nl>
 * @copyright  2021 GrowingMinds
 * @since      0.0.1
 */
export default {
  name : 'Checkbox',
  components: {
		FieldValidator
  },
  props: {
    field: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data : function() {
    return {
      value: this.field.checked,
    }
  },
  methods: {

    /**
     * on blur
     */
    onBlur: function () {
      // on blur validate and send value to tag manager if enabled
      return this.$refs.validator.validate(this.$refs.field, this.value, true);
    },

    /**
     * on focus
     */
    onFocus: function () {

      // in case tracking is activated 
      if (window.utag && window.utag_data) {
        const trackingObj = Object.assign({}, window.utag_data, {
          tealium_event: 'form_focus_change',
          eventLabel: 'focus change',
        });

        console.info('FORM_FOCUS_CHANGE - CHECKBOX');
        trackingObj[this.field.name] = this.value;
        window.utag.link(trackingObj);
      }
    },

    /**
     * Validates the field and returns its valid state
     * @returns bool
     */
    validate: function () {
      return this.$refs.validator.validate(this.$refs.field, this.value);
    }
  },
  watch: {
    value: function () {
      this.validate();
    }
  }
};

</script>
