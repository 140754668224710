import { gsap } from 'gsap'
export const initGradientMovement = () => {
	gsap.timeline({
		repeat: -1,
		repeatRefresh: true
	})
	.to('.gradient svg', 6, {
		x: 'random(-25, 8)vw',
		y: 'random(-15, 50)vh',
		rotate: 'random(-10, 6)deg',
		scaleX: 'random(0.98, 1.15)',
		scaleY: 'random(0.95, 1.5)',
	})
}
